import React, { useEffect, useState } from 'react'
import { Script } from 'gatsby'

const ClutchWidget = ({ reviewId }) => {
  // NOTE: reviewId support requires a paid Clutch+ subscription
  const [loaded, setLoaded] = useState(false)
  const companyId = "32212"

  useEffect(() => {
    if (typeof window !== 'undefined' && loaded) {
      window.CLUTCHCO.Init()
    }
    return
  }, [loaded])

  return (
    <div>
      {reviewId ? (
        <div
          className="clutch-widget"
          data-nofollow="true"
          data-url="https://widget.clutch.co"
          data-widget-type="4"
          data-expandifr="true"
          data-scale="100"
          data-height="auto"
          data-clutchcompany-id={companyId}
          data-reviews={reviewId}
        ></div>
      ) : (
        <div
          className="clutch-widget"
          data-nofollow="true"
          data-url="https://widget.clutch.co"
          data-widget-type="1"
          data-darkbg="true"
          data-height="45"
          data-clutchcompany-id={companyId}
        ></div>
      )}
      <Script
        src="https://widget.clutch.co/static/js/widget.js"
        onLoad={() => setLoaded(true)}
      ></Script>
    </div>
  )
}

export default ClutchWidget
