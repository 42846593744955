import React from 'react'
import smoothScroll from 'scroll-to-element'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons'

const BackToTop = () => {
  let buttonRef
  let lastScrollPosition =
    typeof window !== 'undefined' &&
    (window.pageYOffset || document.documentElement.scrollTop)

  typeof window !== 'undefined' &&
    window.addEventListener('scroll', () => {
      let currentscrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
      if (buttonRef) {
        if (
          currentscrollPosition === 0 ||
          currentscrollPosition > lastScrollPosition
        ) {
          buttonRef.classList.remove('active')
        } else {
          buttonRef.classList.add('active')
        }
      }
      lastScrollPosition =
        currentscrollPosition <= 0 ? 0 : currentscrollPosition
    })

  return (
    <div>
      <a
        ref={r => (buttonRef = r)}
        href="#root_top"
        className="back-to-top"
        title="Back to top page"
        aria-label="Press to back to top page"
        onClick={event => {
          event.preventDefault()
          smoothScroll('#root_top')
        }}
      >
        <span className="back-to-top-icon">
          <FontAwesomeIcon className="back-to-top-icon" icon={faAngleUp} />
        </span>
      </a>
    </div>
  )
}

export default BackToTop
