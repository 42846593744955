import React, { useEffect, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { globalHistory } from '@reach/router'
import Link from './Link'
import EUFlag from '../../assets/images/logos/eu.svg'

export const cookieName =
  process.env.GATSBY_TRACKING_CONSENT_COOKIE_NAME || 'hasTrackingConsent'
export const expirationDays =
  process.env.GATSBY_TRACKING_CONSENT_EXPIRATION_DAYS || 7
export const cookieDateName = 'trackingConsentDate'
export const getConsentValue = () => window.localStorage.getItem(cookieName)
export const hasConsent = () => window.localStorage.getItem(cookieName) === true
export const hasDecided = () => window.localStorage.getItem(cookieName) !== null

const TrackingConsent = ({ location }) => {
  const [hasConsent, setHasConsent] = useState(false)
  const [hasDecided, setHasDecided] = useState(false)
  const [style, setStyle] = useState({})
  const [excludePath, setExcludePath] = useState(false)
  const [isBrowser, setIsBrowser] = useState(false)

  const isExcludedPath = useCallback(path => {
    const excludedPaths = ['/legal/', '/gdpr/']
    return excludedPaths.some(p => p === path)
  }, [])

  const hideComponentStyle = {
    animationName: 'hide-tracking-consent-modal',
    animationDuration: '0.5s',
    animationFillMode: 'backwards',
  }

  useEffect(() => setIsBrowser(typeof window !== 'undefined'), [])

  const setDecisionDate = () => {
    var now = new Date().getTime()
    window.localStorage.setItem(cookieDateName, now)
  }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const isExpired = () => {
    if (!isBrowser || window?.localStorage.getItem(cookieDateName) === null)
      return true
    const cookieDate = window.localStorage.getItem(cookieDateName)
    const then = new Date(parseInt(cookieDate))
    const now = new Date()
    const msToDayFactor = 1000 * 3600 * 24
    const daysBetween = Math.floor(
      (now.getTime() - then.getTime()) / msToDayFactor
    )
    return daysBetween > expirationDays
  }

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const removeCookie = () => {
    if (isBrowser) {
      window.localStorage.removeItem(cookieName)
      window.localStorage.removeItem(cookieDateName)
    }
  }

  const acceptTracking = () => {
    if (isBrowser) {
      setStyle(hideComponentStyle)
      setTimeout(() => {
        window.localStorage.setItem(cookieName, 'true')
        setDecisionDate()
        setHasConsent(true)
        setHasDecided(true)
      }, 500)
    }
  }

  const rejectTracking = () => {
    if (isBrowser) {
      setStyle(hideComponentStyle)
      setTimeout(() => {
        window.localStorage.setItem(cookieName, 'false')
        setDecisionDate()
        setHasConsent(false)
        setHasDecided(true)
      }, 500)
    }
  }

  useEffect(() => {
    if (isExpired()) {
      removeCookie()
    }
    setHasConsent(window.localStorage.getItem(cookieName) === 'true')
    setHasDecided(window.localStorage.getItem(cookieName) !== null)
    if (
      window?.location?.pathname &&
      isExcludedPath(window?.location?.pathname)
    ) {
      setExcludePath(true)
    }
  }, [hasConsent, isExcludedPath, isExpired, removeCookie])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      setExcludePath(
        window?.location?.pathname && isExcludedPath(window?.location?.pathname)
      )
    })
  }, [isExcludedPath])

  if (isBrowser && isExpired()) {
    removeCookie()
  }

  return !isBrowser || hasDecided || excludePath ? (
    <div className="tracking-consent" />
  ) : (
    <div
      id="tracking-consent"
      className="tracking-consent"
      role="dialog"
      aria-label="Tracking Consent"
    >
      <div className="tracking-consent-modal-backdrop modal-backdrop fade show"></div>
      <div
        role="document"
        style={style}
        className={
          'tracking-consent-modal py-4 ' + (hasDecided ? 'hide' : 'show')
        }
      >
        <div className="container">
          <div className="tracking-consent-modal-body">
            <div className="me-3 mb-4 my-sm-1 order-1">
              <Link to="/eu-project/">
                <img
                  src={EUFlag}
                  className="tracking-consent-euflag"
                  alt="EU Flag"
                />
              </Link>
            </div>

            <div className="ml-0 me-auto mb-4 mb-sm-0 text-xs-start order-2">
              <strong>
                We use tracking tools on this website to optimize experience and
                communication.
              </strong>
              <br />
              To find out more about how we use this data, visit our{' '}
              <a
                href="/legal#privacy-policy"
                className="link-unstyled"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
            <button
              id="tracking-consent-btn-accept"
              className="tracking-consent-btn-accept btn btn-dark px-4 me-3 ms-2 my-sm-1 order-3"
              onClick={acceptTracking}
              title="Accept button"
              aria-label="Click to accept tracking"
            >
              <span className="d-inline-block my-3 my-sm-0">
                <FontAwesomeIcon icon={faCheck} />{' '}
                <span className="ms-1">Accept analytics</span>
              </span>
            </button>
            <button
              id="tracking-consent-btn-reject"
              className="tracking-consent-btn-reject btn btn-outline-secondary border-white my-sm-1 order-0 order-sm-4"
              onClick={rejectTracking}
              title="Reject button"
              aria-label="Click to reject tracking"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrackingConsent
