import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faGithub,
  faLinkedin,
} from '@fortawesome/fontawesome-free-brands'
import Link from './Link'
import logo from '../../assets/images/zaven_logo_rgb_color_neg.png'
import ClutchWidget from '../common/ClutchWidget'
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { graphql, useStaticQuery } from 'gatsby'

const Footer = ({ websiteVersion, companyDetails }) => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      services: allServicesYaml(sort: { name: ASC }) {
        ...Services
      }

      technologies: allTechnologiesYaml(sort: { key: ASC }) {
        ...Technologies
      }

      industries: allIndustriesYaml(sort: { name: ASC }) {
        ...Industries
      }

      customerTypes: allCustomerTypesYaml(sort: { name: DESC }) {
        ...CustomerTypes
      }
    }
  `)

  const services = data.services.edges.map((item) => item.node)
  const technologies = data.technologies.edges.map((item) => item.node)
  const industries = data.industries.edges.map((item) => item.node)
  const customerTypes = data.customerTypes.edges.map((item) => item.node)

  const renderFooterServiceMenu = (menuItems) => (
    <Nav className="footer-nav nav">
      {menuItems.map((menuItem) => (
        <NavItem key={menuItem.key}>
          <NavLink href={`/services/${menuItem.slug}`}>{menuItem.name}</NavLink>
        </NavItem>
      ))}
    </Nav>
  )

  return (
    <footer className="footer bg-dark">
      <Container>
        <Row>
          <Col sm={6} className="mb-4 mb-sm-0">
            <div>
              <p className="lead">
                <Link to="/" className="zaven-brand">
                  <img src={logo} className="zaven-logo" alt="Zaven" />
                </Link>
              </p>
              <address>
                <a
                  href={`${companyDetails.address.office.url}`}
                  target="_blank"
                  className="link-unstyled"
                  rel="noopener noreferrer"
                >{`${companyDetails.address.office.street}, ${companyDetails.address.office.city} (${companyDetails.address.office.country})`}</a>
                <br />
                <span className="d-none d-md-inline">
                  <a
                    href="mailto:hello@zaven.co"
                    className="link-unstyled"
                  >{`${companyDetails.contact.email}`}</a>
                  ,{' '}
                  <a
                    href={`tel:${companyDetails.contact.phoneClean}`}
                    className="link-unstyled"
                  >{`${companyDetails.contact.phone}`}</a>
                </span>
              </address>
              <p className="footer-social">
                <a
                  href={companyDetails.linkedin}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Visit our linkedin profile"
                  aria-label="Click here to visit our linkedin profile"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ margin: '0.15rem' }}
                  />
                </a>
                <a
                  href={companyDetails.facebook}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Visit our facebook profile"
                  aria-label="Click here to visit our facebook profile"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ margin: '0.15rem' }}
                  />
                </a>
                <a
                  href={companyDetails.github}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Visit our github profile"
                  aria-label="Click here to visit our github profile"
                >
                  <FontAwesomeIcon
                    icon={faGithub}
                    style={{ margin: '0.15rem' }}
                  />
                </a>
              </p>
            </div>
          </Col>

          <Col sm={6} className="text-sm-end">
            <h1 className="seo-h1 mb-5">
              Zaven is a software house
              <br />
              from Wrocław, Poland.
            </h1>
            <ClutchWidget />
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col sm={12} md={4} lg={2} className="mb-4">
            <div className="seo-h5">Menu</div>
            <Nav className="footer-nav nav">
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/services/">Services</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/projects/">Projects</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/blog/">Blog</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/career/">Career</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about/">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact/">Contact</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/legal/">Privacy Policy</NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm={12} md={4} lg={2}>
            <div className="seo-h5">Services</div>
            {renderFooterServiceMenu(
              services.slice(0, Math.ceil(services.length / 2))
            )}
          </Col>
          <Col sm={12} md={4} lg={3} className="mb-4">
            <div className="seo-h5 d-none d-md-block">&nbsp;</div>
            {renderFooterServiceMenu(
              services.slice(Math.ceil(services.length / 2))
            )}
          </Col>
          <Col sm={12} md={4} lg={1}>
            <div className="seo-h5">Technologies</div>
            {renderFooterServiceMenu(
              technologies.slice(0, Math.ceil(technologies.length / 2))
            )}
          </Col>
          <Col sm={12} md={4} lg={2} className="mb-4">
            <div className="seo-h5 d-none d-md-block">&nbsp;</div>
            {renderFooterServiceMenu(
              technologies.slice(Math.ceil(technologies.length / 2))
            )}
          </Col>
          <Col sm={12} md={4} lg={2} className="mb-4">
            <div className="seo-h5">Industries</div>
            {renderFooterServiceMenu(industries)}
            <br />
            <div className="seo-h5">Customer types</div>
            {renderFooterServiceMenu(customerTypes)}
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col sm={12} md={6}></Col>
          <Col sm={12} md={6}>
            <p className="footer-legal text-start text-md-end">
              v{websiteVersion} © All rights reserved.{' '}
              {companyDetails.legalName}, {companyDetails.address.base.street},{' '}
              {companyDetails.address.base.postal}{' '}
              {companyDetails.address.base.city}, VAT-ID {companyDetails.vat}{' '}
              Reg no. {companyDetails.krs}, {companyDetails.krs_sad}, share
              capital {companyDetails.capital}. Apple Watch® and Swift® are
              trademarks of Apple Inc.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
