import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import * as PropTypes from 'prop-types'
import Footer from '../components/common/Footer'
import BackToTop from '../components/common/BackToTop'
import TrackingConsent from '../components/common/TrackingConsent'
import favicon16 from '../assets/images/favicon-16x16.png'
import favicon32 from '../assets/images/favicon-32x32.png'
import appleTouchIcon from '../assets/images/apple-touch-icon.png'
import safariPinnedTab from '../assets/images/safari-pinned-tab.svg'

const structuredLDJSON = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Zaven - Software Development Poland',
  url: 'http://zaven.co',
  logo: 'https://zaven.co/static/dcb25d34c245812b676ef6ff50194466/6bb1b/zaven_logo_rgb_color_pos.png',
  image:
    'https://zaven.co/static/cf5ed0af63ce9b5ed177fd16dca97ce6/2310b/intro_bg.jpg',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Tęczowa 11',
    addressLocality: 'Wrocław',
    postalCode: '53-601',
    addressCountry: 'PL',
  },
  location: {
    '@type': 'Place',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '51.1064696',
      longitude: '17.0173343',
    },
  },
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+48 71 728 80 80',
    contactType: 'customer service',
  },
  sameAs: [
    'https://www.facebook.com/zaven.co/',
    'https://www.linkedin.com/company/zaven-software-development-poland',
    'https://github.com/zavenco',
  ],
}

const metadata = [
  {
    name: 'description',
    content:
      'We’re a process-driven software development company from Poland. We help businesses to connect with their audiences using the high-end web and mobile apps.',
  },
  {
    name: 'keywords',
    content:
      'mobile development, software company Poland, best mobile app design, swift iOS developer, software near-sourcing, outsourcing offshore, android apps, ios apps',
  },
  {
    property: 'og:description',
    content:
      'We’re a process-driven software development company from Poland. We help businesses to connect with their audiences using the high-end web and mobile apps.',
  },
  {
    property: 'og:title',
    content: 'Zaven | Software Development',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'fb:app_id',
    content: '193502947368996',
  },
  {
    property: 'og:url',
    content: 'https://zaven.co',
  },
  {
    property: 'og:image',
    content:
      'https://zaven.co/static/cf5ed0af63ce9b5ed177fd16dca97ce6/2310b/intro_bg.jpg',
  },
  {
    property: 'og:image:alt',
    content: 'Background',
  },
  {
    property: 'og:image:type',
    content: 'image/jpg',
  },
  {
    property: 'twitter:description',
    content:
      'We’re a process-driven software development company from Poland. We help businesses to connect with their audiences using the high-end web and mobile apps.',
  },
  {
    property: 'twitter:title',
    content: 'Zaven | Software Development',
  },
  {
    property: 'twitter:url',
    content: 'https://zaven.co',
  },
  {
    property: 'twitter:image',
    content:
      'https://zaven.co/static/cf5ed0af63ce9b5ed177fd16dca97ce6/2310b/intro_bg.jpg',
  },
  {
    property: 'twitter:image:alt',
    content: 'Background',
  },
  {
    property: 'twitter:image:type',
    content: 'image/jpg',
  },
]

function Layout({ children, location }) {
  const data = useStaticQuery(graphql`
    query {
      layout_info: dataJson {
        capital
        krs_sad
        krs
        vat
        facebook
        linkedin
        github
        ...Contact
      }
      layout_site: site {
        siteMetadata {
          title
          version
        }
      }
    }
  `)

  const isFooterHidden =
    location.pathname.includes('/career/job')

  return (
    <div id="root_top">
      <Helmet
        title={data.layout_site.siteMetadata.title}
        meta={metadata}
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            href: `${favicon16}`,
            sizes: '16x16',
          },
          {
            rel: 'icon',
            type: 'image/png',
            href: `${favicon32}`,
            sizes: '32x32',
          },
          {
            rel: 'apple-touch-icon',
            href: `${appleTouchIcon}`,
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: `${safariPinnedTab}`,
            color: '#00b2ad',
          },
        ]}
      >
        <html lang="en" />
        <script type="application/ld+json">
          {JSON.stringify(structuredLDJSON)}
        </script>
        <meta name="msapplication-TileColor" content="#2c3e50" />
        <meta name="theme-color" content="#2c3e50" />
      </Helmet>
      {children}
      {!isFooterHidden && (
        <Footer
          companyDetails={data.layout_info}
          websiteVersion={data.layout_site.siteMetadata.version}
        />
      )}
      <BackToTop />
      {typeof window !== 'undefined' &&
      window.sessionStorage.getItem('zavenGdpr') ? null : (
        <TrackingConsent />
      )}
    </div>
  )
}

Layout.propTypes = { children: PropTypes.any }

export default Layout
