require('./node_modules/@fortawesome/fontawesome-svg-core/styles.css')
require('./src/assets/styles/main.scss')
require('intersection-observer')
const fontAwesome = require('./node_modules/@fortawesome/fontawesome-svg-core')

fontAwesome.config.autoAddCss = false

// Fix page scroll kept despite route change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)

  return false
}
